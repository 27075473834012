import React, { Component } from 'react'
import styles from './team-member.module.css'

class TeamMember extends Component {
  render() {
    const { name, image, bio, role, quote } = this.props
    return (
      <div className={styles.teamMember}>
        <div
          className={styles.photo}
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
        <div className={styles.bioWrapper}>
          <h3>{name}</h3>
          <span>{role}</span>
          <div
            className={styles.bio}
            dangerouslySetInnerHTML={{ __html: bio }}
          />
          {quote ? (
            <div
              className={styles.quote}
              dangerouslySetInnerHTML={{ __html: quote }}
            />
          ) : null}
        </div>
      </div>
    )
  }
}

export default TeamMember
