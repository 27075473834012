import React from 'react'
import Layout from '../components/layout/'
import Callout from '../components/callout/'
import PageWrapper from '../components/pageWrapper'
import TeamMember from '../components/teamMember'
import Header from '../components/header'
import styles from './styles/meet-the-team.module.css'

const MeetTheTeam = ({ data }) => {
  const stats = data.stats.edges[0].node

  return (
    <Layout callout={<Callout />}>
      <Header stats={stats} />
      <PageWrapper>
        <div className={styles.contentBlock}>
          <h1>Meet the Team</h1>
          <p>
            Since it was founded in 2008, GJIL has developed into a successful
            non-profit with a volunteer Executive Director, Lindsay Keller, and
            an exceptional Board of Directors.
          </p>
        </div>
        <section className={styles.teamMembers}>
          {data.allContentfulPeople.edges.map(function(edge, i) {
            return (
              <TeamMember
                key={i}
                name={edge.node.name}
                image={edge.node.photo.file.url}
                bio={edge.node.bio.childMarkdownRemark.html}
                quote={edge.node.quote.childMarkdownRemark.html}
                role={edge.node.role}
              />
            )
          })}
        </section>
      </PageWrapper>
    </Layout>
  )
}

export const teamPageQuery = graphql`
  query teamPageQuery {
    stats: allContentfulStats {
      edges {
        node {
          id
          booksSent
          kidsRegistered
        }
      }
    }
    allContentfulPeople(sort: { fields: [sortOrder, name], order: ASC }) {
      edges {
        node {
          id
          name
          role
          sortOrder
          bio {
            childMarkdownRemark {
              html
            }
          }
          quote {
            childMarkdownRemark {
              html
            }
          }
          photo {
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default MeetTheTeam
